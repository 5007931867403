import { ButtonLink } from '@/components/ui/ButtonLinks'
import { ResponsiveSpacer } from '@/components/spacing/Spacer'
import cx from 'classnames'
import {
  ComponentComparisonTable,
  ComponentComparisonTableRow,
} from 'types/generated/contentful-types'

export const CompareTableFragment = `
  foraColumnHeading
  othersColumnHeading
  rowsCollection {
    items {
      internalLabel
      title
      foraValue
      othersValue
    }
  }
  ctaText
  ctaUrl
  sectionId
`

type CompareTableGridItemProps = Omit<ComponentComparisonTableRow, '_id'>

const CompareTableGridItem = ({
  title,
  foraValue,
  othersValue,
}: CompareTableGridItemProps) => {
  return (
    <>
      <div className="hidden py-4 border-b lg:py-8 lg:block border-darkShell fora-text-h7">
        <span>{title}</span>
      </div>
      <div className="flex flex-col gap-2 py-4 pr-12 border-b lg:pr-24 lg:py-8 border-darkShell text-darkStone fora-text-body-2 md:fora-text-body-1">
        <span className="uppercase lg:hidden fora-text-eyebrow-4">{title}</span>
        {foraValue}
      </div>
      <div className="flex flex-col gap-2 py-4 pl-12 border-b lg:pl-24 lg:py-8 border-darkShell text-darkStone fora-text-body-2 md:fora-text-body-1">
        <span className="uppercase lg:hidden fora-text-eyebrow-4">{title}</span>
        {othersValue}
      </div>
    </>
  )
}

type CompareTableProps = Omit<ComponentComparisonTable, '_id'>

export default function CompareTable(
  {
    sectionId,
    foraColumnHeading,
    othersColumnHeading,
    rowsCollection,
    ctaText,
    ctaUrl,
    urlTarget,
    contentfulMetadata,
    sys,
  }: CompareTableProps,
  className: string
): JSX.Element {
  return (
    <>
      <div
        id={sectionId || undefined}
        className={cx('grid grid-cols-2 lg:grid-cols-3', className)}
        data-name="compare-table"
      >
        <div className="hidden border-b border-darkShell lg:block" />
        <div className="pb-4 pr-12 border-b border-darkShell lg:pb-8 lg:pr-24">
          <h3 className="fora-text-h4 lg:fora-text-h5">{foraColumnHeading}</h3>
        </div>
        <div className="px-12 pb-4 border-b border-darkShell lg:pb-8 lg:px-24">
          <h3 className="fora-text-h4 lg:fora-text-h5">
            {othersColumnHeading}
          </h3>
        </div>
        {rowsCollection &&
          rowsCollection.items.map((comparisonItem, i) => (
            <CompareTableGridItem
              key={`ComparisonItem_${i}`}
              {...comparisonItem}
              contentfulMetadata={contentfulMetadata}
              sys={sys}
            />
          ))}
      </div>
      {ctaText && ctaUrl && (
        <>
          <ResponsiveSpacer size="3XL" />
          <div className="flex justify-center w-full">
            <ButtonLink
              target={(urlTarget as '_self' | '_blank') || '_self'}
              href={ctaUrl}
              text={ctaText}
              theme="primary"
            />
          </div>
        </>
      )}
    </>
  )
}
